<i18n>
[
    "login__emailSignInConfirm--title",
    "login__emailSignInConfirm--subtitle",
    "global__done",
]
</i18n>

<template>
    <div class="c-email-login-feedback">
        <LoginContainer
            :title="$t('login__emailSignInConfirm--title')"
            :subtitle="$t('login__emailSignInConfirm--subtitle')"
            :shouldHideForm="true"
        >
            <template #after-form>
                <div class="c-email-login-feedback__action-buttons">
                    <BaseButton @click="$router.push(homeRoute)">
                        {{ $t('global__done') }}
                    </BaseButton>
                </div>
            </template>
        </LoginContainer>
    </div>
</template>

<script>
import { genericRouteNames } from '~coreModules/core/js/router-constants';

import LoginContainer from '~coreRoutes/login/components/LoginContainer.vue';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'LoginEmailSent',
    components: {
        LoginContainer,
        BaseButton,
    },
    data() {
        return {
            homeRoute: { name: genericRouteNames.home },
        };
    },
};
</script>

<style lang="scss">

</style>
