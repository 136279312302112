export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "login__emailSignInConfirm--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Your Inbox"])},
        "login__emailSignInConfirm--subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We emailed you a personalized sign-in link. The link will expire after 30 minutes. If you don't see it right away, please check your junk mail"])},
        "global__done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
      }
    }
  })
}
